import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import DefaultInput from "../forms/inputs/defaultInput";
import CustomErrorMessage from "../forms/inputsWithRef/customErrorMessage";
import styles from "./dndCollapsible.module.css";

interface Props {
  visibilityRuleset?: any;
  mandatory?: boolean;
  onHandleCheckboxChange?: () => void;
  onHandleRulesetChange?: (event: any, id?: any) => void;
  id?: any;
  visibility_ruleset?: any;
}

export function DnDCollapsible(props: Props) {
  const [ruleset, setRuleset] = useState<any>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");

  useEffect(() => {
    if (props.visibility_ruleset) {
      if (typeof props.visibility_ruleset === "string") {
        setRuleset(props.visibility_ruleset);
      } else {
        setRuleset(JSON.stringify(props.visibility_ruleset));
      }
    } else if (props.visibility_ruleset === undefined) {
      setRuleset("{}");
    }
  }, [props.visibility_ruleset]);

  const handleRulesetChange = (event: any) => {
    setRuleset(event.target.value);
  };

  useEffect(() => {
    if (props.onHandleRulesetChange) {
      if (validateJson()) {
        props.onHandleRulesetChange(ruleset, props.id);
      }
    }
  }, [ruleset]);

  const validateJson = () => {
    try {
      JSON.parse(ruleset);
    } catch (e) {
      setError(true);
      setErrorMessage(e);
      return false;
    }
    setError(false);
    return true;
  };

  return (
    <Accordion
      className={styles.accordion}
      disableGutters={true}
      sx={{ "&.Mui-expanded": { background: "lightgray" } }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ "&.Mui-expanded": { fontWeight: "bold" } }}
      >
        <span>Einstellungen</span>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.wrapper}>
          <DefaultInput
            label="Visibility"
            infoTooltip={true}
            infoTooltipMessage={
              'Beispiele: {"VARIABLENNAME": {"is": "yes"}} | {"VARIABLENNAME": {"not": "no"}} | {"VARIABLENNAME": {"gt": 0.5}} | {"VARIABLENNAME": {"lt": 1.4}} | {"VARIABLENNAME": {"in": [0.1, 0.2, 5.0]}} | {"VARIABLENNAME": {"nin": ["Heuschnupfen", "Ausschlag"]}}'
            }
            // defaultValue={ruleset}
            value={ruleset}
            onChange={handleRulesetChange}
            onBlur={validateJson}
          />
          {error && (
            <CustomErrorMessage
              error={error}
              errorMessage={errorMessage.message}
            />
          )}

          {/* <label className={styles.visibilityInputStyle}>
            Visibility
            <input
              defaultValue={props.visibilityRuleset}
              value={ruleset}
              onChange={handleRulesetChange}
            />
          </label> */}
          <label className={styles.mandatoryCheckbox}>
            Pflichtfeld
            <input
              type="checkbox"
              defaultChecked={props.mandatory}
              onChange={props.onHandleCheckboxChange}
            />
          </label>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

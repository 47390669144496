import { MouseEventHandler, useState } from "react";

interface Props {
  onClick?: MouseEventHandler;
  style?: any;
  backgroundColor?: string;
  hoverColor?: string;
  hoverTextColor?: string;
  children: any;
  enableHover?: boolean;
  padding?: string;
  showCursor?: boolean;
}

export default function RoundedContentBox(props: Props) {
  const [isHover, setIsHover] = useState(false);

  let backgroundColor = "#ffffff";
  let textColor = "#000000";
  let hoverColor = "#8c1ec8";

  if (props.backgroundColor) {
    backgroundColor = props.backgroundColor;
  }

  if (props.hoverColor) {
    hoverColor = props.hoverColor ? props.hoverColor : hoverColor;
  }
  if (props.hoverTextColor) {
    textColor = props.hoverTextColor ? props.hoverTextColor : textColor;
  }

  const handleMouseEnter = () => {
    props.enableHover && setIsHover(true);
  };

  const handleMouseLeave = () => {
    props.enableHover && setIsHover(false);
  };

  const contentBoxStyle = {
    backgroundColor: backgroundColor,
    boxShadow: isHover
      ? `0 0 5px ${hoverColor}`
      : "0px 8px 24px -4px #E0E0E040",
    padding: props.padding ? props.padding : "1.5rem",
    borderRadius: "1rem",
    gap: "1rem",
    overflow: "hidden",
    color: textColor,
    cursor: props.showCursor ? "pointer" : "",
    boxSizing: "border-box",
    ...props.style,
  } as React.CSSProperties;

  return (
    <div
      onClick={props.onClick}
      style={contentBoxStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.children}
    </div>
  );
}

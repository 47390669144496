import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getDate from "date-fns/getDate";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import getMinutes from "date-fns/getMinutes";
import getHours from "date-fns/getHours";
import GeneralListItem from "../general/generaListItem";
import { api } from "../../helper/api";
import { Grid } from "@mui/material";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import dayjs from "dayjs";
import styles from "./timeSlots.module.css";

interface Props {
  selectedDay?: any;
  appointmentInformation?: any;
  handleShowConfirmationAfterPick: () => void;
  handleGetResponseAftetPick: (data: any) => void;
}

export function TimeSlots(props: Props) {
  const [slotData, setSlotData] = useState<any>([]);
  const [error, setError] = useState<any>();

  // const navigate = useNavigate();
  const { appointmentrequestId } = useParams() as {
    appointmentrequestId: string;
  };

  let convertedDate: any;
  if (props.selectedDay) {
    const year = getYear(props.selectedDay);
    let month: any = getMonth(props.selectedDay) + 1;
    let day: any = getDate(props.selectedDay);

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    convertedDate = year + "-" + month + "-" + day;
  }
  useEffect(() => {
    if (props.selectedDay) {
      const additionalUrlParameters: any = {};
      const additionalRouteParts: any = {};
      additionalRouteParts["free_slots"] = "/";
      additionalUrlParameters["day"] = convertedDate;
      api.genericApiRequest({
        entity: "appointmentRequest",
        method: "get",

        successHandler: (res: any) => {
          const data = res.data.results;
          const sortedData = data.sort((a: any, b: any) =>
            a.start_date.localeCompare(b.start_date)
          );
          setSlotData(sortedData);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        entityId: appointmentrequestId,
        additionalRouteParts: additionalRouteParts,
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
      });
    }
  }, [props.selectedDay]);

  const createAppointment = (id: any, label: any, skipQuestion: false) => {
    const result = {
      slot_id: id,
    };

    const confirmDate = dayjs(props.selectedDay).format("DD.MM.YYYY");

    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(t("timeslot_confirm_pick", { date: confirmDate, time: label }))
        /*eslint-enable */
      ) {
        return;
      }
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const additionalRouteParts: any = {};
    additionalRouteParts["create_from_slot"] = "/";

    api.genericApiRequest({
      entity: "appointment",
      method: "post",
      successHandler: (res: any) => {
        const response = res.data;
        props.handleGetResponseAftetPick(response);
        props.handleShowConfirmationAfterPick();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      additionalRouteParts: additionalRouteParts,
      parametersToRender: {
        depth: "1",
      },
      submitData: result,
      config: config,
    });
  };

  const items = [];
  for (const key in slotData) {
    const slot = slotData[key];
    const slotId = slot.id;
    const slotTime = slot.start_date;

    const convertedSlotTimeToDate = new Date(slotTime);
    const timeSlotHours =
      getHours(convertedSlotTimeToDate) < 10
        ? "0" + getHours(convertedSlotTimeToDate)
        : getHours(convertedSlotTimeToDate);

    const timeSlotMinutes =
      getMinutes(convertedSlotTimeToDate) < 10
        ? "0" + getMinutes(convertedSlotTimeToDate)
        : getMinutes(convertedSlotTimeToDate);

    const timeSlotTimeLabel = timeSlotHours + ":" + timeSlotMinutes;

    items.push(
      <Grid
        xs={3}
        sm={2}
        md={1.5}
        lg={1}
        item
        key={slotId}
        onClick={() => createAppointment(slotId, timeSlotTimeLabel, false)}
      >
        <GeneralListItem
          allowHover={true}
          hideButton={true}
          wrapperClassName={styles.generalListItemWrapper}
        >
          {timeSlotTimeLabel}
        </GeneralListItem>
      </Grid>
    );
  }

  return (
    <>
      <Grid container columns={6} columnSpacing={"1rem"}>
        {items}
      </Grid>

      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}

import { ErrorMessage } from "@hookform/error-message";
import { DatePicker } from "@mui/x-date-pickers";
import { t } from "i18next";
import React, { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomErrorMessage from "../inputsWithRef/customErrorMessage";
import dayjs from "dayjs";

interface Props {
  name: string;
  label: string;
  predefinedvalue?: any;
}

const GenericDatePicker: FC<Props> = ({ name, label, predefinedvalue }) => {
  const [dateValue, setDateValue] = useState<any>(null);
  const { setValue, control } = useFormContext();

  useEffect(() => {
    if (predefinedvalue) {
      const date = dayjs(predefinedvalue);
      setDateValue(date);
      setValue(name, predefinedvalue);
    }
  }, [predefinedvalue]);

  const onChangeHandler = (newDate: any, onChange?: any) => {
    const value = newDate;
    const tempDate = dayjs(value.$d).format("DD.MM.YYYY");
    onChange(tempDate);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, ...rest },
          fieldState: { error },
          formState: { errors },
        }) => (
          <div>
            <DatePicker
              {...rest}
              label={label}
              value={dateValue}
              onChange={(newValue) => {
                onChangeHandler(newValue, onChange), setDateValue(newValue);
              }}
              slotProps={{
                textField: {
                  name: t("birthdate"),
                  error: error ? true : false,
                  sx: {
                    width: "100%",
                    "&& .MuiOutlinedInput-root": {
                      borderRadius: "0.5rem",
                    },
                  },
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name={t("birtdate")}
              render={({ message }) => (
                <CustomErrorMessage error={"error"} errorMessage={message} />
              )}
            />
          </div>
        )}
      />
    </>
  );
};

export default GenericDatePicker;

import { FC, useState } from "react";
import { Box, Divider, Drawer, IconButton, List } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../state/store";
import DropDown from "../forms/inputs/dropDown";
import { t } from "i18next";
import { setParent } from "../state/parent/parentSlice";
import { navigateTo } from "../state/navigation/navigateToSlice";
import NavigationRoutes from "./navigationRoutes";
import styles from "./navigationDrawer.module.css";

import { useLocation } from "react-router-dom";

interface Props {
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => any;
  subdomain: string;
  closeDrawer: () => void;
}

const NavigationDrawer: FC<Props> = ({
  drawerOpen,
  toggleDrawer,
  subdomain,
  closeDrawer,
}) => {
  const [subRoutesOpen, setSubroutesOpen] = useState<boolean>(false);

  const children = useSelector((state: RootState) => state.children);
  const dispatch = useDispatch();

  const handleChange = (selectedOption: any) => {
    if (
      selectedOption &&
      selectedOption.value !== store.getState().parent.companyId
    ) {
      dispatch(
        setParent({
          companyId: selectedOption.value,
          companyName: selectedOption.label,
          subjectId: selectedOption.subjectId,
        })
      );
      dispatch(navigateTo({ navigate: true, target: "/" }));
    }
  };
  const resetSelectedChild = () => {
    dispatch(
      setParent({
        companyId: "",
        companyName: "",
        subjectId: "",
      })
    );
    dispatch(navigateTo({ navigate: true, target: "/" }));
  };

  const handleSubrouteOpen = () => {
    setSubroutesOpen(!subRoutesOpen);
  };
  const openSubroutes = () => {
    setSubroutesOpen(true);
  };

  const closeSubroutes = () => {
    setSubroutesOpen(false);
  };
  const location = useLocation();

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      className={styles.drawer}
    >
      <Box className={styles.boxWrapper}>
        <div className={styles.navigationDrawer_headerWrapper}>
          {drawerOpen && children.length !== 0 && subdomain === "manage" && (
            <DropDown
              options={children}
              wrapperClassName={styles.dropDown__naviagationDrawer}
              placeholder={t("select_branch")}
              onChange={handleChange}
              isClearable={true}
              resetInput={resetSelectedChild}
              defaultValue={store.getState().parent.companyId}
              drawerOpen={drawerOpen}
            />
          )}

          <IconButton onClick={toggleDrawer(false)} className={styles.iconContainer}>
            <CloseIcon className={styles.closeIcon} />
          </IconButton>
        </div>
        <Divider className={styles.divider}/>
        <List
          className={styles.navigationDrawer_list}
          sx={{
            "&& .Mui-selected, && .Mui-selected:hover, && .MuiListItem-root:hover":
              {
                bgcolor: "#f4f2f2",
                "&, & .MuiListItemIcon-root": {
                  color: "#000000",
                },
              },
            "& .MuiListItemButton-root:hover": {
              bgcolor: "#f4f2f2",

              "&, & .MuiListItemIcon-root": {
                color: "#000000",
              },
            },
            // paddingTop: "0",
          }}
        >
          <NavigationRoutes
            drawerOpen={drawerOpen}
            subdomain={subdomain}
            handleSubrouteOpen={handleSubrouteOpen}
            closeSubroutes={closeSubroutes}
            subRouteOpen={subRoutesOpen}
            closeDrawer={closeDrawer}
            location={location}
            openSubRoutes={openSubroutes}
          />
        </List>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;

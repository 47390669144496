import React, { FC } from "react";
import Button from "./button";

interface Props {
  title: string;
  href: string;
  style?: any;
  variant?: "contained" | undefined;
  color?:
    | "primary"
    | "secondary"
    | "warning"
    | "error"
    | "success"
    | "info"
    | "inherit"
    | undefined;
  withTooltip?: boolean;
  tooltipTitle?: any;
  textTransform?: any;

  tooltipPlacement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
}

const DownloadButton: FC<Props> = ({
  title,
  href,
  style,
  variant,
  color,
  withTooltip,
  tooltipTitle,
  tooltipPlacement,
  textTransform,
}) => {
  const downloadFile = () => {
    window.location.href = href;
  };
  return (
    <Button
      title={title}
      onClick={downloadFile}
      style={style}
      variant={variant}
      color={color}
      withTooltip={withTooltip}
      tooltipTitle={tooltipTitle}
      tooltipPlacement={tooltipPlacement}
      textTransform={textTransform}
    />
  );
};

export default DownloadButton;

import { ErrorMessage } from "@hookform/error-message";
import React, { FC, HTMLInputTypeAttribute, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomErrorMessage from "../inputsWithRef/customErrorMessage";
import { TextField } from "@mui/material";
import styles from "./genericInputField.module.css";

interface Props {
  name: string;
  label: string;
  inputType: HTMLInputTypeAttribute;
  predefinedvalue?: any;
}

const GenericInputField: FC<Props> = ({
  name,
  label,
  inputType,
  predefinedvalue,
}) => {
  const [inputValue, setInputValue] = useState<any>("");
  const { setValue, control } = useFormContext();

  useEffect(() => {
    if (predefinedvalue) {
      if (predefinedvalue === null) {
        setInputValue("");
        setValue(name, "");
      } else {
        setInputValue(predefinedvalue);
        setValue(name, predefinedvalue);
      }
    }
  }, [predefinedvalue]);

  const onChangeHandler = (newValue: any, onChange?: any) => {
    const value = newValue.target.value;
    onChange(value);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, ...rest },
          fieldState: { error },
          formState: { errors },
        }) => (
          <>
            <TextField
              {...rest}
              error={error ? true : false}
              type={inputType}
              label={label}
              name={name}
              onChange={(event) => {
                onChangeHandler(event, onChange),
                  setInputValue(event.target.value);
              }}
              className={styles.input}
              value={inputValue}
              sx={{
                width: "100%",
                "&& .MuiOutlinedInput-root": {
                  borderRadius: "0.5rem",
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <CustomErrorMessage error={"error"} errorMessage={message} />
              )}
            />
          </>
        )}
      />
    </>
  );
};

export default GenericInputField;

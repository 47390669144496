import { useEffect, useState } from "react";
import GeneralListItem from "../general/generaListItem";
import { IAppointment } from "../../types/Entities";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Grid } from "@mui/material";
import { DotLoader } from "react-spinners";
import { api } from "../../helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentDashboard } from "../state/appointment/appointmentDashboardSlice";
import { RootState } from "../state/store";
import dayjs from "dayjs";
import Button from "../forms/inputs/button";
import { t } from "i18next";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import DropDown from "../forms/inputs/dropDown";
import { setAppointmentDashboardStatusFilter } from "../state/appointmentDashboard/statusFilterSlice";
import { setAppointmentDashboardCategoryFilter } from "../state/appointmentDashboard/categoryFilterSlice";
import { setAppointmentDashboardExecutedByFilter } from "../state/appointmentDashboard/executedByFilterSlice";
import { setAppointmentDashboardSelectedCalendarDate } from "../state/appointmentDashboard/selectedCalendarDaySlice";
import { setAppointmentDashboardSubjectFilter } from "../state/appointmentDashboard/subjectFilterSlice";
import { getAppointmentDetails, statusArray } from "../../helper/statusOptions";
import styles from "./appointmentList.module.css";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";

export default function AppointmentList(props: any) {
  const persistedSelectedDate = useSelector(
    (state: RootState) => state.appointmentDashboard
  );
  const selectedCalendarDay = useSelector(
    (state: RootState) => state.appointmentDashboardSelectedCalendarDate
  );
  let defaultDate = new Date(persistedSelectedDate.selectedDashboardDate);
  if (selectedCalendarDay.value !== "") {
    defaultDate = new Date(selectedCalendarDay.value);
  }

  const [appointmentDays, setAppointmentDays]: any = useState<string[]>([]);
  const [daysWithAppointments, setDaysWithAppointments]: any = useState({});
  const [selectedDay, setSelectedDay] = useState(defaultDate);
  const [appointmentsForSelectedDay, setAppointmentsForSelectedDay]: any =
    useState<IAppointment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeStarteDate, setActiveStartDate] = useState<Date>();
  const [error, setError] = useState<any>();
  const [statusOptions, setStatusOptions] = useState<any>([]);
  const [selectedStatusForFiltering, setSelectedStatusForFiltering] =
    useState<any>("");
  const [mainCategoryOptions, setMaincategoryOptions] = useState<any>([]);
  const [
    selectedMaincategoryForFiltering,
    setSelectedMaincategoryForFiltering,
  ] = useState<any>("");
  const [executedByOptions, setExecutedByOptions] = useState<any>([]);
  const [selectedExecutedByForFiltering, setSelectedExecutedByForFiltering] =
    useState<any>("");
  // eslint-disable-next-line
  const [subjectOptions, setSubjectOptions] = useState<any>([]);
  const [selectedSubjectForFiltering, setSelectedSubjectForFiltering] =
    useState<any>([]);

  const [statusFilterApplied, setStatusFilterApplied] =
    useState<boolean>(false);
  const [categoryFilterApplied, setCategoryFilterApplied] =
    useState<boolean>(false);
  const [executedByFilterApplied, setExecutedByFilterApplied] =
    useState<boolean>(false);
  const [subjectFilterApplied, setSubjectFilterApplied] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const today = dayjs(new Date());

  const selectedStatusFilter = useSelector(
    (state: RootState) => state.appointmentDashboardStatusFilter
  );
  const selectedCategoryFilter = useSelector(
    (state: RootState) => state.appointmentDashboardCategoryFilter
  );
  const selectedExecutedByFilter = useSelector(
    (state: RootState) => state.appointmentDashboardExecutedByFilter
  );
  const selectedSubjectFilter = useSelector(
    (state: RootState) => state.appointmentDashboardSubjectFilter
  );

  //get and set categories & user of kaer for filterDropDownsOptions
  useEffect(() => {
    setIsLoading(true);
    api.genericApiRequest({
      method: "get",
      entity: "mainCategory",
      successHandler: (res: any) => {
        const data = res.data.results;
        const mainCatOptions = [];
        for (const key in data) {
          const category = data[key];
          const label = category.name;
          const value = category.id;
          mainCatOptions.push({ value: value, label: label });
        }
        setMaincategoryOptions(mainCatOptions);
      },
      failHandler: (error: any) => setError(error),
    });
    const additionalUserParameters: any = {};
    additionalUserParameters["impersonate_subject"] =
      "11111111-1111-1111-1111-444444444444";
    additionalUserParameters["is_active"] = "true";

    api.genericApiRequest({
      entity: "user",
      method: "get",
      parametersToRender: {
        additionalUrlParameters: additionalUserParameters,
        depth: "0",
      },

      successHandler: (res: any) => {
        const tempUsers = [];
        for (const key in res.data.results) {
          const item = res.data.results[key];
          const allUsers = {
            value: item.id,
            label: item.last_name + ", " + item.first_name,
          };
          tempUsers.push(allUsers);
        }
        setExecutedByOptions(
          tempUsers.sort((a: any, b: any) => a.label.localeCompare(b.label))
        );
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });

    setStatusOptions(statusArray);
    const nowDate = new Date();
    reloadAppointmentDays(nowDate.getMonth(), nowDate.getFullYear(), () => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedStatusFilter.value && selectedStatusFilter.value !== "") {
      setSelectedStatusForFiltering(selectedStatusFilter.value);
      setStatusFilterApplied(true);
    }
    if (selectedCategoryFilter.value && selectedCategoryFilter.value !== "") {
      setSelectedMaincategoryForFiltering(selectedCategoryFilter.value);
      setCategoryFilterApplied(true);
    }
    if (
      selectedExecutedByFilter.value &&
      selectedExecutedByFilter.value !== ""
    ) {
      setSelectedExecutedByForFiltering(selectedExecutedByFilter.value);
      setExecutedByFilterApplied(true);
    }
    if (
      selectedSubjectFilter.value &&
      selectedSubjectFilter.value !== "" &&
      selectedSubjectFilter.value !== undefined
    ) {
      setSelectedSubjectForFiltering({
        value: selectedSubjectFilter.value,
        label: selectedSubjectFilter.label,
      });
      setSubjectFilterApplied(true);
    }
  }, [
    selectedStatusFilter,
    selectedCategoryFilter,
    selectedExecutedByFilter,
    selectedSubjectFilter,
  ]);

  useEffect(() => {
    reloadAppointmentDays(
      dayjs(activeStarteDate).format("M"),
      dayjs(activeStarteDate).format("YYYY"),
      () => {
        setIsLoading(false);
      },
      true
    );
  }, [
    statusFilterApplied,
    categoryFilterApplied,
    executedByFilterApplied,
    subjectFilterApplied,
  ]);

  useEffect(() => {
    const daysWithAppointments: any = {};

    for (const key in appointmentDays) {
      const dateOfAppointment = new Date(appointmentDays[key]);
      const appointmentYear = dateOfAppointment.getFullYear();
      const appointmentMonth = dateOfAppointment.getMonth();
      const appointmentDay = dateOfAppointment.getDate();

      if (
        !Object.getOwnPropertyDescriptor(daysWithAppointments, appointmentYear)
      ) {
        daysWithAppointments[appointmentYear] = {};
      }

      if (
        !Object.getOwnPropertyDescriptor(
          daysWithAppointments[appointmentYear],
          appointmentMonth
        )
      ) {
        daysWithAppointments[appointmentYear][appointmentMonth] = {};
      }

      daysWithAppointments[appointmentYear][appointmentMonth][appointmentDay] =
        true;
    }

    setDaysWithAppointments(daysWithAppointments);
  }, [appointmentDays]);

  useEffect(() => {
    const applyFilter = () => {
      setIsLoading(true);
      selectedDay.setHours(12); // quick hack, because of timesaving it will be otherwise the day before
      const dateStringForSelectedDay = selectedDay.toISOString().split("T")[0];

      const additionalUrlParameters: any = {};
      const additionalRouteParts: any = {};

      additionalUrlParameters["date"] = dateStringForSelectedDay;
      if (selectedStatusForFiltering !== "" && statusFilterApplied) {
        additionalUrlParameters["status__in"] = selectedStatusForFiltering;
      }
      if (selectedMaincategoryForFiltering !== "" && categoryFilterApplied) {
        additionalUrlParameters["categoryexamination__category__in"] =
          selectedMaincategoryForFiltering;
      }
      if (selectedExecutedByForFiltering !== "" && executedByFilterApplied) {
        additionalUrlParameters["executed_by"] = selectedExecutedByForFiltering;
      }

      if (selectedSubjectForFiltering.value !== "" && subjectFilterApplied) {
        additionalUrlParameters["subject_id"] =
          selectedSubjectForFiltering.value;
      }
      additionalRouteParts["dashboard"] = "/";

      api.genericApiRequest({
        entity: "appointment",
        method: "get",
        successHandler: (res: any) => {
          const data = res.data.results;
          setAppointmentsForSelectedDay(data);
          setIsLoading(false);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        additionalRouteParts: additionalRouteParts,
        parametersToRender: {
          limit: "150",
          offset: "0",
          depth: "1",
          additionalUrlParameters: additionalUrlParameters,
        },
      });
    };
    applyFilter();
  }, [
    selectedDay,
    focus,
    statusFilterApplied,
    executedByFilterApplied,
    categoryFilterApplied,
    subjectFilterApplied,
    selectedStatusForFiltering,
    selectedMaincategoryForFiltering,
    selectedExecutedByForFiltering,
    selectedSubjectForFiltering,
  ]);

  const reloadAppointmentDays = (
    month: any,
    year: any,
    successCallback: any,
    shouldNotCorrectMonth?: boolean
  ) => {
    let correctMonth = month + 1;

    if (shouldNotCorrectMonth) {
      correctMonth = month;
    }

    const additionalUrlParameters: any = {};
    const additionalRouteParts: any = {};
    additionalRouteParts["fetch_available_appointments"] = "/";

    additionalUrlParameters["month"] = correctMonth;
    additionalUrlParameters["year"] = year;

    if (selectedStatusForFiltering !== "") {
      additionalUrlParameters["status__in"] = selectedStatusForFiltering;
    }
    if (selectedMaincategoryForFiltering !== "") {
      additionalUrlParameters["categoryexamination__category__in"] =
        selectedMaincategoryForFiltering;
    }
    if (selectedExecutedByForFiltering !== "") {
      additionalUrlParameters["executed_by"] = selectedExecutedByForFiltering;
    }
    if (
      selectedSubjectForFiltering.value !== "" &&
      selectedSubjectForFiltering.value !== undefined
    ) {
      additionalUrlParameters["subject_id"] = selectedSubjectForFiltering.value;
    }

    api.genericApiRequest({
      entity: "appointment",
      method: "get",

      successHandler: (res: any) => {
        setAppointmentDays(res.data.distinct_days);
        successCallback();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      additionalRouteParts: additionalRouteParts,
      parametersToRender: {
        additionalUrlParameters: additionalUrlParameters,
        depth: "0",
      },
    });
  };

  //eslint-disable-next-line
  const handleMonthChange = ({ action, activeStartDate, value, view }: any) => {
    activeStartDate.setHours(12);
    setActiveStartDate(activeStartDate.setHours(12));

    reloadAppointmentDays(
      activeStartDate.getMonth(),
      activeStartDate.getFullYear(),
      () => {
        if (appointmentsForSelectedDay.length === 0) {
          setIsLoading(false);
        }
      },
      false
    );
  };
  //added a second case because of the different metricNaming in LiveSystem
  const getAppointmentAddress = (appointment: any) => {
    const buildingInformation = appointment?.building_information;

    if (
      buildingInformation.street_house &&
      buildingInformation.zip &&
      buildingInformation.city
    ) {
      return (
        buildingInformation.street_house +
        ", " +
        buildingInformation.zip +
        " " +
        buildingInformation.city
      );
    } else {
      return "-";
    }
  };

  const preRenderItemsForSelectedDay = () => {
    const itemsPerHour: any = {};
    const preRenderedItems = [];

    for (const key in appointmentsForSelectedDay) {
      const appointment = appointmentsForSelectedDay[key];
      const dateObject = new Date(appointment.datetime);
      const appointmentExaminations = [];
      const appointmentAddress = getAppointmentAddress(appointment);

      for (const key in appointment.examination_name_set) {
        appointmentExaminations.push(appointment.examination_name_set[key]);
      }
      let mainCategoryName = appointment?.maincategory_set?.[0]?.name;
      if (mainCategoryName === undefined) {
        mainCategoryName = "-";
      }
      const { backgroundColor, appointmentStatus } = getAppointmentDetails(
        appointment.status
      );

      const item = (
        <GeneralListItem
          key={appointment.id}
          onClick={() => {
            props.onClickHandler(appointment.id);
          }}
          onClickElement="wrapper"
          wrapperClassName={styles.generalListItemWrapper}
          itemBackgroundColor={backgroundColor}
          hideButton={true}
        >
          <table className={styles.tableStyle} border={0}>
            <colgroup>
              <col className={styles.col20} />
              <col className={styles.col40} />
              <col className={styles.col20} />
            </colgroup>
            <tbody>
              <tr>
                <td className={styles.cellStyle}>
                  <strong>
                    {dateObject.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    Uhr
                  </strong>
                </td>
                <td className={styles.cellStyle}>
                  Firma: <strong>{appointment.company_name}</strong>
                </td>
                <td className={styles.cellStyle}>
                  Team: <strong>{appointment.team.name}</strong>
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className={styles.cellStyle}>
                  {appointmentExaminations?.join(", ")}
                </td>
                <td className={styles.cellStyle}>
                  Standort: <strong>{appointmentAddress}</strong>
                </td>
                <td className={styles.cellStyle}>
                  Hauptkategorie: <strong>{mainCategoryName}</strong>
                </td>
              </tr>
              <tr>
                <td className={styles.cellStyle}>
                  Proband: <strong>{appointment.subject.name}</strong>
                </td>
                <td className={styles.cellStyle}>
                  Status: <strong>{appointmentStatus}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </GeneralListItem>
      );

      const appointmentHour = dateObject.getHours();

      if (!Object.getOwnPropertyDescriptor(itemsPerHour, appointmentHour)) {
        itemsPerHour[appointmentHour] = [];
      }

      itemsPerHour[appointmentHour].push(item);
    }

    for (const i in Object.keys(itemsPerHour)) {
      const key = Object.keys(itemsPerHour)[i];
      preRenderedItems.push(
        <h2 key={key + "_timeslot_for_day"}>{key}:00 Uhr</h2>
      );
      for (const j in itemsPerHour[key]) {
        const appointment = itemsPerHour[key][j];
        preRenderedItems.push(appointment);
      }
    }

    return preRenderedItems;
  };

  const calendarTileDisabled = ({ date, view }: any) => {
    if (view === "month") {
      if (
        daysWithAppointments[date.getFullYear()] &&
        daysWithAppointments[date.getFullYear()][date.getMonth()] &&
        daysWithAppointments[date.getFullYear()][date.getMonth()][
          date.getDate()
        ]
      ) {
        return false;
      }
    }

    return true;
  };

  const onChange = (selectedDay: any, focus?: boolean) => {
    setSelectedDay(selectedDay);
    dispatch(
      setAppointmentDashboard({
        selectedDashboardDate: selectedDay.toISOString(),
      })
    );
    if (focus) {
      setActiveStartDate(undefined);
    }
    setAppointmentsForSelectedDay([]);
    dispatch(
      setAppointmentDashboardSelectedCalendarDate(selectedDay.toISOString())
    );
  };

  const handleFilter = (event: any, name: any) => {
    if (event && typeof event === "object" && event.value) {
      if (name === "status") {
        setSelectedStatusForFiltering(event.value);
        dispatch(setAppointmentDashboardStatusFilter(event.value));
      }
      if (name === "mainCategory") {
        setSelectedMaincategoryForFiltering(event.value);
        dispatch(setAppointmentDashboardCategoryFilter(event.value));
      }
      if (name === "executedBy") {
        setSelectedExecutedByForFiltering(event.value);
        dispatch(setAppointmentDashboardExecutedByFilter(event.value));
      }
      if (name === "subject") {
        if (
          event.value !== selectedSubjectForFiltering &&
          event.label !== selectedSubjectForFiltering.label
        ) {
          setSelectedSubjectForFiltering({
            value: event.value,
            label: event.label,
          });
          dispatch(
            setAppointmentDashboardSubjectFilter({
              value: event.value,
              label: event.label,
            })
          );
        }
      }
    }
  };

  const handleClearDropdown = (name: string) => {
    if (name === "status") {
      setSelectedStatusForFiltering("");
      setStatusFilterApplied(false);
      dispatch(setAppointmentDashboardStatusFilter(""));
    }
    if (name === "mainCategory") {
      setSelectedMaincategoryForFiltering("");
      setCategoryFilterApplied(false);
      dispatch(setAppointmentDashboardCategoryFilter(""));
    }
    if (name === "executedBy") {
      setSelectedExecutedByForFiltering("");
      setExecutedByFilterApplied(false);
      dispatch(setAppointmentDashboardExecutedByFilter(""));
    }
    if (name === "subject") {
      setSelectedSubjectForFiltering([]);
      setSubjectFilterApplied(false);
      dispatch(setAppointmentDashboardSubjectFilter({ value: "", label: "" }));
    }
  };

  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <h1>
              Termin Dashboard ({dayjs(selectedDay).format("DD.MM.YYYY")})
            </h1>
          </Grid>
          <Grid item xs={9}>
            {preRenderItemsForSelectedDay()}
          </Grid>
          <Grid item xs={3} className={styles.stickyContainer}>
            <DropDown
              options={mainCategoryOptions}
              onChange={(event: any) => handleFilter(event, "mainCategory")}
              name={t("main_category")}
              label={t("main_category")}
              isClearable={true}
              resetInput={() => handleClearDropdown("mainCategory")}
              defaultValue={selectedMaincategoryForFiltering}
            />
            <DropDown
              options={statusOptions}
              onChange={(event: any) => handleFilter(event, "status")}
              name={t("status")}
              label={t("status")}
              isClearable={true}
              resetInput={() => handleClearDropdown("status")}
              defaultValue={selectedStatusForFiltering}
            />

            <DropDown
              options={executedByOptions}
              onChange={(event: any) => handleFilter(event, "executedBy")}
              name={t("executed_by")}
              label={t("executed_by")}
              isClearable={true}
              resetInput={() => handleClearDropdown("executedBy")}
              defaultValue={selectedExecutedByForFiltering}
            />
            <DropDownSubjectSearch
              options={subjectOptions}
              subdomain={"admin"}
              onChange={(event: any) => handleFilter(event, "subject")}
              name={t("subject")}
              customLabel={t("subject_company_label")}
              isClearable={true}
              resetInput={() => handleClearDropdown("subject")}
              useGlobalState={false}
              preExistingValue={selectedSubjectForFiltering}
            />

            <Calendar
              onChange={(selectedDay) => onChange(selectedDay)}
              minDetail={"month"}
              onActiveStartDateChange={handleMonthChange}
              tileDisabled={calendarTileDisabled}
              value={selectedDay}
            />
            <Grid container item className={styles.dateButtonContainer}>
              <Grid item>
                <Button
                  disabled={
                    today.format("DD/MM/YYYY") ===
                      dayjs(selectedDay).format("DD/MM/YYYY") &&
                    today.format("DD/MM/YYYY") ===
                      dayjs(selectedDay).format("DD/MM/YYYY") &&
                    dayjs(activeStarteDate).format("MM") === today.format("MM")
                  }
                  title={t("date_today")}
                  textTransform={"none"}
                  onClick={() => onChange(new Date(), true)}
                  variant="outlined"
                  className={styles.dateButton}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={
                    dayjs(activeStarteDate).format("MM") ===
                    dayjs(selectedDay).format("MM")
                  }
                  title={t("date_selected")}
                  textTransform={"none"}
                  variant="outlined"
                  className={styles.dateButton}
                  onClick={() => onChange(new Date(selectedDay), true)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}

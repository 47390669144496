import { useState } from "react";
import Button from "../forms/inputs/button";
import DefaultInput from "../forms/inputs/defaultInput";
import { IUploadFileInfo } from "../../types/FileItem";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import "./documents.css";
import { Button as MuiButton } from "@mui/material";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { store } from "../state/store";
import GenericNotification from "../notification/genericNotification";
import styles from "./fileUpload.module.css";

interface Props {
  target_filename?: any;
  subjectId?: string;
  folderId?: string | any;
  handleUploadSuccess?: () => void;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  onCancel?: any;
  type?: string;
  name?: string;
  label?: string;
  externalSubmitEvent?: any;
  entity?: string;
  companyId?: string;
  companySubjectId?: string;
  style?: React.CSSProperties;
  justifyContent?: string;
  acceptedFileInfo?: boolean;
  handleEntries?: (skippedEntries: []) => void;
  accept?: any;
  withButtons?: boolean;
  title?: any;
}

export default function FileUpload(props: Props) {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<IUploadFileInfo[] | any>([]);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const handleAddFile = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileSubmit = () => {
    const formData = new FormData();
    const selectedChildSubjectId: any = store.getState()?.parent?.subjectId;

    formData.append("file", selectedFile);
    formData.append("original_filename", selectedFile?.name);
    if (props.target_filename) {
      formData.append("target_file_name", props.target_filename);
    }
    if (props.companySubjectId) {
      formData.append("impersonate_subject", props.companySubjectId);
    }
    if (selectedChildSubjectId !== "") {
      formData.append("impersonate_subject", selectedChildSubjectId);
    }
    if (props.folderId) {
      formData.append("folder_id", props.folderId);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    api.genericApiRequest({
      entity: props.entity === "user" ? "user/worker_csv" : "file",
      method: "post",
      successHandler: (res: any) => {
        if (props.entity === "user") {
          props.handleEntries?.(res.data);
          setNotificationVariant("success");
          setNotificationMessage(t("create_successful"));
          setShowNotification(true);
        }
        props.handleUploadSuccess?.();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: formData,
      config: config,
    });
  };

  let cancelButtonTitle = t("cancel");

  if (props.cancelButtonTitle) {
    cancelButtonTitle = props.cancelButtonTitle;
  }

  let submitButtonTitle = t("upload");

  if (props.submitButtonTitle) {
    submitButtonTitle = props.submitButtonTitle;
  }
  const acceptedFileInfoField = (
    <section className={styles.acceptedFileInfo}>
      <h5>Dateiupload-Hinweis</h5>
      <span>
        Dateitypen:{" "}
        {props.accept
          ? props.accept
          : " .doc, .docx, .png, .jpg, .jpeg, .xls, .xlsx, .pdf .csv"}
      </span>
      <span>Dateigröße: max. 20MB</span>
    </section>
  );

  const noDisplay = { display: "none" };

  return (
    <>
      <Grid container spacing={2} className={styles.fileUploadContainer}>
        <Grid item className={styles.fileUploadInput}>
          <DefaultInput
            type={props.type}
            name={props.name}
            label={props.label}
            onChange={handleAddFile}
            accept={props.accept}
            labelStyle={noDisplay}
          ></DefaultInput>

          <label htmlFor={props.name}>
            <MuiButton
              variant="contained"
              color="primary"
              component="span"
              className={styles.fileUploadButton}
            >
              {props.title ? props.title : t("choose_file")}
            </MuiButton>
          </label>
          <span className={styles.selectedFileName}>{selectedFile.name}</span>
        </Grid>
        <Grid item xs={"auto"}>
          {props.acceptedFileInfo && acceptedFileInfoField}
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={`${styles.buttonContainer} ${props.justifyContent ? styles.justifyContent : ''}`}
        >
          <Button
            title={cancelButtonTitle}
            onClick={() => {
              if (props.onCancel) {
                props.onCancel();
              } else setSelectedFile([]);
            }}
          />
          <Button
            disabled={selectedFile?.length === 0}
            variant="contained"
            color="secondary"
            title={submitButtonTitle}
            onClick={handleFileSubmit}
          />
        </Grid>
      </Grid>
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}

import { HTMLInputTypeAttribute, ReactNode, useEffect, useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CustomErrorMessage from "../forms/inputsWithRef/customErrorMessage";
import { DEFAULTPICKERDATE } from "../../types/Entities";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DropDownSubjectSearch } from "../forms/inputs/dropDownSubjectSearch";
import { IDropDownOption } from "../../types/DropDown";
import { t } from "i18next";
import { api } from "../../helper/api";
import { DropDownCreatable } from "../forms/inputs/dropDownCreateable";
import GenericCrudSelect from "./genericCrudTableModalWrapperElements/genericCrudSelect";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { useDispatch } from "react-redux";
import { useSignOut } from "react-auth-kit";
import styles from "./genericCrudTableModalWrapperField.module.css";

interface Props {
  entity?: string;
  companySubjectId?: string;
  multiselect?: boolean;
  textarea?: boolean;
  onChange?: (value: any) => void;
  id?: string;
  name?: string;
  label: string;
  value?: any;
  selectOptions?: any;
  isJson?: boolean;
  hideField?: boolean;
  datePicker?: boolean;
  dateTimePicker?: boolean;
  validate?: boolean;
  rule?: any;
  errorMessage?: any;
  helperText?: string;
  autoComplete?: string;
  companyId?: any;
  defaultPickerDate?: any;
  required: boolean;
  errorMessageValidation?: string;
  modalMode?: string;
  asyncEntity?: string;
  asyncDropDown?: boolean;
  userEmails?: string[];
  queryParameter?: "users" | "companies"; //if set asyncDropDown returns just the specified
  customLabel?: string; // customLabel for asyncDropDrown
  setCompanyId?: boolean; // used in companyList/Parent for using the actual companyId instead of SubjectId
  filterByCategory?: boolean;
  disableField?: boolean;
  createAble?: boolean;
  maxLengthMessage?: string;
  maxLength?: any;
  additionalUrlParameters?: any;
  subjectIdForBuildings?: any;
  additionalComponentForModal?: ReactNode;
  getOnlyActiveUsers?: any;
  type?: HTMLInputTypeAttribute;
  showAddressForBuildingSelect?: boolean;
}

interface Options extends IDropDownOption {
  treeId?: string;
}

export default function GenericCrudTableModalWrapperField(props: Props) {
  const [error, setError] = useState<any>(null);

  const defaultDate =
    props.defaultPickerDate === DEFAULTPICKERDATE.TODAY ? dayjs() : null;

  const [entityItems, setEntityItems] = useState<any>([]);
  const [values, setValues] = useState<any>("");
  const [multiselect, setMultiselect] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<any>(defaultDate);
  const [dateTimeValue, setDateTimeValue] = useState<any>(dayjs());
  /*eslint-disable */
  const [asyncOptions, setAsyncOptions] = useState<Options[]>([]);
  /*eslint-enable */
  const [createableOptions, setCreateableOptions] = useState<any>([]);
  const [getOptionsFinished, setGetOptionsFinished] = useState<boolean>(false);

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  const {
    setValue,
    getValues,
    control,
    // formState: { errors },
  } = useFormContext();
  const additionalUrlParameters: any = {};

  const dispatch = useDispatch();
  const signOut = useSignOut();

  if (props.entity === "department" && subdomain === "admin") {
    additionalUrlParameters["impersonate_subject"] = props.companySubjectId;
  }
  if (props.additionalUrlParameters) {
    for (const key in props.additionalUrlParameters) {
      additionalUrlParameters[key] = props.additionalUrlParameters[key];
    }
  }

  if (props.entity === "building" && subdomain === "admin") {
    additionalUrlParameters["subject"] = props.subjectIdForBuildings
      ? props.subjectIdForBuildings
      : props.companySubjectId;
  }
  if (props.entity === "user" && props.getOnlyActiveUsers) {
    additionalUrlParameters["impersonate_subject"] =
      props.getOnlyActiveUsers.companySubjectId;
    additionalUrlParameters["is_active"] = props.getOnlyActiveUsers.status;
  }

  const combinedSignout = () => {
    dispatch({ type: "clear" });
    signOut();
  };

  useEffect(() => {
    if ((props.entity || props.asyncDropDown) && entityItems.length === 0) {
      if (props.asyncDropDown) {
        if (props.value) {
          if (typeof props.value === "object") {
            const id = props.value?.id;
            const name = props.value?.name;
            const preSelected = [];
            preSelected.push({ value: id, label: name });
            setValues(preSelected);
            setEntityItems([{ id: id, name: name }]);
          }
        }
      } else {
        api.genericApiRequest({
          method: "get",
          entity: props.entity,
          parametersToRender: {
            depth: "0",
            additionalUrlParameters: additionalUrlParameters,
          },
          successHandler: (res: any) => {
            // return if company has no departments - prevent ongoing calls
            if (res.data.results.length === 0) {
              setGetOptionsFinished(true);
              return;
            } else {
              setEntityItems(res.data.results);
              setGetOptionsFinished(true);
            }
            if (props.multiselect) {
              //if the the field is a multiselect, get the IDs of the selected items and add them to an array
              const selectedIds = [];
              for (const key in props.value) {
                selectedIds.push(props.value[key].id);
              }
              setMultiselect(true);
              setValues(selectedIds);
              setValue(props.name as string, selectedIds);
              props.onChange?.(selectedIds);
              setGetOptionsFinished(true);
            } else {
              //if the field is a single select, we only need the id of the item, as a single value
              if (props.value) {
                //if the field is an object, take it's id
                if (props.value.id) {
                  setValues(props.value.id);
                  setValue(props.name as string, props.value.id);
                  setGetOptionsFinished(true);
                } else {
                  //if the field is not an object, then it's just an id string
                  setValues(props.value);
                  setValue(props.name as string, props.value);
                  setGetOptionsFinished(true);
                }
              } else {
                setValues("");
                setValue(props.name as string, "");
                setGetOptionsFinished(true);
              }
            }
          },
          failHandler: (error: any) => {
            if (error === "logout") {
              combinedSignout();
              setError(error);
            } else console.log(error);
          },
        });
      }
    }
  }, [
    props.entity,
    props.value,
    entityItems,
    props.multiselect,
    props.asyncDropDown,
  ]);
  useEffect(() => {
    if (props.value) {
      setValue(props.name as string, props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.asyncEntity) {
      setValue(props.name as string, []);
    }
  }, [props.asyncEntity]);

  useEffect(() => {
    if (props.createAble) {
      setCreateableOptions(props.selectOptions);
      setValue(props.name as string, []);
    }
  }, [props.createAble]);

  useEffect(() => {
    if (props.dateTimePicker && !props.value) {
      const value = dateTimeValue.$d.toISOString();
      props.onChange?.(value);
    }

    if (props.datePicker && props.value) {
      let date = props.value;
      if (date instanceof Date) {
        date = dayjs(date);
        setDateValue(date);
      } else {
        date = new Date(props.value);
        date = dayjs(date);
        setDateValue(date);
      }
    }
    if (props.dateTimePicker && props.value) {
      let date = props.value;
      if (date instanceof Date) {
        date = dayjs(date);
        setDateTimeValue(date);
      } else {
        date = new Date(props.value);
        date = dayjs(date);
        setDateTimeValue(date);
      }
    }
  }, [props.datePicker, props.dateTimePicker, props.value]);
  const handleChange = (
    event: any,
    onChange: any,
    multiselect?: boolean,
    valueArray?: any
  ) => {
    const {
      target: { value },
    } = event;
    setValues(typeof value === "string" ? value.split(",") : value);

    if (multiselect) {
      props.onChange?.(valueArray);
      onChange(valueArray);
    } else {
      props.onChange?.(value);
      onChange(value);
    }
  };

  const isAnalog = useWatch({ name: "is_analog" });
  const disabledEmailForAnalogUser =
    isAnalog === true || isAnalog === "true" ? true : false;

  const handleDateChange = (newDate: any, onChange?: any) => {
    const value = newDate.$d.toISOString();
    setDateValue(newDate);
    props.onChange?.(value);
    onChange(value);
  };
  const handleDateTimeChange = (newDate: any, onChange?: any) => {
    const value = newDate.$d.toISOString();
    setDateTimeValue(newDate);
    onChange(value);
    props.onChange?.(value);
  };

  const handleAsyncChange = (selectedOption: any, onChange: any) => {
    const value = [];
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      if (props.setCompanyId) {
        value.push({
          value: selectedOption.companyId,
          label: selectedOption.label,
        });
        props.onChange?.(selectedOption.companyId);
      } else {
        value.push({
          value: selectedOption.value,
          label: selectedOption.label,
        });
        props.onChange?.(selectedOption.value);
      }
    }
    onChange(value);
  };
  const handleUpdateOptions = (newOption: any) => {
    setCreateableOptions([...createableOptions, newOption]);
  };
  const handleCreateableChange = (selectedOption: any, onChange: any) => {
    const value = [];
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      value.push(selectedOption.value);
      props.onChange?.(selectedOption.value);
    }
    onChange(value);
  };

  const inputStyle = {
    display: props.hideField ? "none" : "",
    width: "100%",
    maxWidth: "400px",
  } as React.CSSProperties;

  const textAreaStyle = {
    display: props.hideField ? "none" : "",
    width: "100%",
    maxWidth: "400px",
    whiteSpace: "preserve",
  } as React.CSSProperties;

  if (
    multiselect ||
    props.entity ||
    (props.selectOptions && !props.createAble)
  ) {
    let newArray = entityItems;
    let newValues = values;
    // following filteredValue of filterByCategory is used in AppointmentEditor
    if (props.entity && props.entity === "examination") {
      if (props.filterByCategory) {
        const filtered = entityItems.filter((item: any) =>
          item.category_set.some((x: any) => x.id === getValues("category"))
        );
        newArray = filtered;
      }
    }
    if (props.selectOptions) {
      newArray = props.selectOptions;
      newValues = "";
      if (props.value !== null && props.value !== undefined) {
        if (props.value === false) {
          newValues = "false";
        } else {
          newValues = props.value;
        }
      }
    }
    return (
      <>
        <GenericCrudSelect
          name={props.name}
          control={control}
          required={props.required}
          errorMessage={props.errorMessage}
          entity={props.entity}
          entityItems={newArray}
          id={props.id}
          label={props.label}
          onHandleChange={handleChange}
          values={newValues}
          modalMode={props.modalMode}
          multiSelect={props.multiselect}
          getValues={getValues}
          getOptionsFinished={getOptionsFinished}
          showAddressForBuildingSelect={props.showAddressForBuildingSelect}
        />
      </>
    );
  } else if (props.createAble && props.selectOptions) {
    let value = "";
    if (props.value !== null && props.value !== undefined) {
      if (props.value === false) {
        value = "false";
      } else {
        value = props.value;
      }
    }

    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          rules={{
            required: {
              value: props.required,
              message: props.errorMessage,
            },
          }}
          render={({
            field: { onChange, ref, ...rest },
            fieldState: { error },
            formState: { errors },
          }) => (
            <div className={styles.selectWrapper}>
              <DropDownCreatable
                {...rest}
                ref={ref}
                options={createableOptions}
                name={props.name}
                defaultValue={value}
                label={props.label}
                isClearable={true}
                disabled={props.disableField}
                onChange={(selectedOption: any) => {
                  handleCreateableChange(selectedOption, onChange);
                }}
                error={error}
                updateOptions={handleUpdateOptions}
              />
              <ErrorMessage
                errors={errors}
                name={props.name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      </>
    );
  } else if (props.asyncDropDown) {
    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          rules={{
            required: {
              value: props.required,
              message: props.errorMessage,
            },
          }}
          render={({
            field: { onChange, ref, ...rest },
            fieldState: { error },
            formState: { errors },
          }) => (
            <div className={styles.selectWrapper}>
              <DropDownSubjectSearch
                {...rest}
                customRef={ref}
                wrapperClassName={styles.dropDownSubjectSearch}
                name={
                  props.required ? `${props.label + "\u{002A}"}` : props.label
                }
                error={error}
                options={asyncOptions as []}
                preExistingValue={values}
                onChange={(selectedOption: any) =>
                  handleAsyncChange(selectedOption, onChange)
                }
                subdomain={subdomain}
                queryParameter={props.queryParameter}
                customLabel={props.customLabel}
                companyIdforParent={getValues("id")}
                isClearable={true}
                useGlobalState={false}
              />
              <ErrorMessage
                errors={errors}
                name={props.name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      </>
    );
  } else if (props.isJson) {
    let textAreaValue = props.value;

    if (typeof props.value === "object") {
      textAreaValue = JSON.stringify(props.value);
    }
    return (
      <>
        {!props.hideField && (
          <Controller
            name={props.name as string}
            control={control}
            rules={{
              required: {
                value: props.required,
                message: props.errorMessage,
              },
              pattern: {
                value: props.rule,
                message: props.errorMessageValidation
                  ? props.errorMessageValidation
                  : t("error_msg_invalid_characters_change_your_input"),
              },
            }}
            render={({
              field: { onChange, ...rest },
              fieldState: { error },
              formState: { errors },
            }) =>
              ((props.modalMode === "edit" && textAreaValue) ||
                props.modalMode === "insert") && (
                <div>
                  <TextField
                    {...rest}
                    error={error ? true : false}
                    label={
                      props.required
                        ? `${props.label + "\u{002A}"}`
                        : props.label
                    }
                    name={props.name}
                    onChange={(event: any) => {
                      handleChange(event, onChange);
                    }}
                    value={textAreaValue}
                    style={textAreaStyle}
                    multiline
                    inputProps={{ sx: { resize: "vertical" } }}
                    rows={8}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={props.name as string}
                    render={({ message }) => (
                      <CustomErrorMessage
                        error={"error"}
                        errorMessage={message}
                      />
                    )}
                  />
                </div>
              )
            }
          />
        )}
      </>
    );
  } else if (props.datePicker) {
    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          rules={{
            validate: {
              value: (value: any) => {
                if (
                  props.validate &&
                  dayjs(value).format("DD.MM.YYYY") ===
                    dayjs().format("DD.MM.YYYY")
                ) {
                  return props.errorMessageValidation;
                }
                if (
                  props.validate &&
                  dayjs(value).format("DD.MM.YYYY") ===
                    dayjs().format("01.01.1900")
                ) {
                  return t("field_required");
                }
              },
            },
            required: {
              value: props.required,
              message: props.errorMessage,
            },
          }}
          render={({
            field: { onChange, ...rest },
            fieldState: { error },
            formState: { errors },
          }) => (
            <div>
              <DatePicker
                {...rest}
                label={
                  props.required ? `${props.label + "\u{002A}"}` : props.label
                }
                value={dateValue}
                onChange={(newValue) => handleDateChange(newValue, onChange)}
                slotProps={{
                  textField: {
                    name: props.name,
                    error: error ? true : false,
                  },
                }}
                className={styles.datePicker}
              />
              <ErrorMessage
                errors={errors}
                name={props.name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      </>
    );
  } else if (props.dateTimePicker) {
    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          rules={{
            required: {
              value: props.required,
              message: props.errorMessage,
            },
          }}
          render={({
            field: { onChange, ...rest },
            // fieldState: { error },
            formState: { errors },
          }) => (
            <div>
              <DateTimePicker
                {...rest}
                label={
                  props.required ? `${props.label + "\u{002A}"}` : props.label
                }
                value={dateTimeValue}
                onChange={(newValue) => {
                  handleDateTimeChange(newValue, onChange);
                }}
                ampm={false}
                slotProps={{
                  textField: {
                    name: props.name,
                  },
                }}
                className={styles.dateTimePicker}
              />
              <ErrorMessage
                errors={errors}
                name={props.name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      </>
    );
  } else {
    let fieldValue = props.value;
    if (!fieldValue) {
      fieldValue = "";
    }

    if (props.textarea && props.textarea === true) {
      return (
        <>
          {!props.hideField && (
            <>
              <Controller
                name={props.name as string}
                control={control}
                rules={{
                  pattern: {
                    value: props.rule,
                    message: props.errorMessageValidation
                      ? props.errorMessageValidation
                      : t("error_msg_invalid_characters_change_your_input"),
                  },
                  required: {
                    value: props.required,
                    message: props.errorMessage,
                  },
                  maxLength: {
                    value: props.maxLength,
                    message: props.maxLengthMessage
                      ? props.maxLengthMessage
                      : "",
                  },
                }}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                  formState: { errors },
                }) => (
                  <>
                    <TextField
                      {...rest}
                      error={error ? true : false}
                      label={
                        props.required
                          ? `${props.label + "\u{002A}"}`
                          : props.label
                      }
                      name={props.name}
                      onChange={(event: any) => {
                        handleChange(event, onChange);
                      }}
                      value={fieldValue}
                      style={textAreaStyle}
                      multiline
                      inputProps={{
                        sx: { resize: "vertical" },
                        // maxLength: 255,
                      }}
                      rows={8}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={props.name as string}
                      render={({ message }) => (
                        <CustomErrorMessage
                          error={"error"}
                          errorMessage={message}
                        />
                      )}
                    />
                    {props.additionalComponentForModal &&
                      props.modalMode === "edit" &&
                      props.additionalComponentForModal}
                  </>
                )}
              />
            </>
          )}
        </>
      );
    }

    return (
      <>
        {!props.hideField && (
          <div>
            <Controller
              name={props.name as string}
              control={control}
              rules={{
                pattern: {
                  value: props.rule,
                  message: props.errorMessageValidation
                    ? props.errorMessageValidation
                    : t("error_msg_invalid_characters_change_your_input"),
                },
                required: {
                  value: disabledEmailForAnalogUser ? false : props.required,
                  message: props.errorMessage,
                },

                maxLength: {
                  value: props.maxLength,
                  message: props.maxLengthMessage ? props.maxLengthMessage : "",
                },
                validate: {
                  value: (value: any) => {
                    if (!disabledEmailForAnalogUser) {
                      if (
                        props.name === "email" &&
                        props.modalMode === "insert"
                      ) {
                        if (
                          props.validate &&
                          props.userEmails?.includes(value)
                        ) {
                          return props.errorMessageValidation;
                        }
                      }
                    }
                  },
                },
              }}
              render={({
                field: { onChange, ...rest },
                fieldState: { error },
                formState: { errors },
              }) => (
                <>
                  <Tooltip
                    title={
                      subdomain === "manage" &&
                      props.modalMode === "edit" &&
                      getValues("is_registered") === true &&
                      props.name === "email"
                        ? t("email_no_edit")
                        : ""
                    }
                    placement="top"
                  >
                    <TextField
                      {...rest}
                      error={error ? true : false}
                      type={props.type}
                      label={
                        props.required
                          ? `${props.label + "\u{002A}"}`
                          : props.label
                      }
                      name={props.name}
                      onChange={(event: any) => {
                        handleChange(event, onChange);
                      }}
                      value={fieldValue}
                      style={inputStyle}
                      helperText={props.helperText}
                      autoComplete={props.autoComplete}
                      sx={{}}
                      disabled={
                        (subdomain === "manage" &&
                          props.modalMode === "edit" &&
                          getValues("is_registered") === true &&
                          props.name === "email") ||
                        (props.name === "email" && disabledEmailForAnalogUser)
                      }
                    />
                  </Tooltip>
                  <ErrorMessage
                    errors={errors}
                    name={props.name as string}
                    render={({ message }) => (
                      <CustomErrorMessage
                        error={"error"}
                        errorMessage={message}
                      />
                    )}
                  />
                </>
              )}
            />
          </div>
        )}
        {error && error !== "" && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
            }}
          />
        )}
      </>
    );
  }
}

//TODO CUSTOMERROR
